.main-container__logoImg{
  width: 200px;
  height: 50px;
}

@media only screen and (max-width: 640px) {
  .main-container__logoImg{
    width: 150px;
    height: 40px;
  }

  .main-container__logoImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

}
.main-container__logoImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homeMain-container__nav li, .homeMain-container__contact{
  color: #fff;
}

.homeMain{
  height: 100vh;
  min-height: 670px;
  position: relative;
}

.homeMain__container{
  width: 100%;
  height: 100%;
}

.homeMain__para{
  position: absolute;
  bottom: 80px;
  right: 0;
}

@media (min-width:367px) and (max-width:375px) {
  .homeMain__para{
    margin-top: 60px;
  }
}

.navBar-default{
  color: #ED1C24;
}

.homeMain__title{
    font-size: 60px;
    color: #fff;
    font-weight: bold;
}

.homeMain-container__nav a li{
  transition: all 0.3s ease;
}


.home-btn{
    width: 280px;
    height: 43px;
    border-radius: 6px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #ED1C24;
    background-color: #fff;
}

.home-btn__free{
  background-color: #ED1C24;
  color: #fff;
  width: 43px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 1px 9px;
  display: inline-block;
  z-index: 0;
  margin: -5px 0px -5px 10px;
}

.project-x__red{
  color: #ED1C24;
  font-weight: 600;
}

@media (min-width:1536px) and (max-width:1600px) {
  .homeMain-container__nav{
    padding: 0px 40px;
  }
}



/* .home-btn__free::before{
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  transform: skewY(-45deg);
  z-index: 4;
  background-color: #da474cde;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: glow;
} */

.home-btn__free::after{
  content: "Free";
  position: relative;
  z-index: 2;
}

@keyframes glow {
  from{
    transform: translateX(-30px);
  }
  to{
    transform: translateX(230%);
  }
}

.homeMain__para{
    width: 450px;
    height: fit-content;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.line {
    width: 50px;
    height: 1px;
    background: #fff;
    margin-bottom: 10px;
}

.caps{
    color: #ED1C24;
    font-weight: 700;
  }
  

.anime__color{
    animation: text 2s ease-in-out infinite;
  }
  @keyframes text {
    0%, 50% {
      color: #ED1C24; 
    }
    51%, 100% {
      color: #fff;
    }
  }
  
  .anime__col {
    animation: color 2s ease-in-out infinite;
    animation-delay: 3s; 
  }
  
  @keyframes color {
    0%, 50% {
      color: #ED1C24; 
    }
    51%, 100% {
      color: #fff; 
    }
  }

@media only screen and (max-width: 768px) {
  .homeMain__title{
    font-size: 50px;
  }
}

@media only screen and (max-width:375px) {
  .homeMain__para{
    width: 100%;
    position: static;
  }

}

@media (min-width:376px) and (max-width: 460px){
  .homeMain__para{
    width: 100%;
  }
}

.side-icons__{
  font-size: 22px;
}

.side__socialIcon{
  width: 50px;
  height: 160px;
  background-color: #ED1C24;
  position: fixed;
  top: 70%;
  z-index: 9999;
  left: 10px;
  border-radius: 8px;
  color: #fff;
}

.link{
  position: relative;
}

.link::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 4px;
  bottom: -5px;
  left: 0;
  background-color: #ED1C24;
  transition: width 0.4s ease-in-out;
}

.link:hover.link::after{
  width: 120%;
  transition: width 0.4s ease-in-out;
}