.navBar{
    width: 300px;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    right: -300px;
    top: 0;
    transition: all 0.5s ease;
    z-index: 999999;
}

.navBar.open{
    right: 0px;
    transition: all 0.5s ease;

}

.navBar-default{
    color: #ED1C24;
}

.navBar-container ul li:hover{
    color: #ED1C24;
    cursor: pointer;
}

.navBar-closeIcon{
    font-size: 22px;
    cursor: pointer;
}