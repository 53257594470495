@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.whyChoose-content__img{
    width: 100%;
    height: 120px;
}

.whyChoose-content__img img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.whyChoose-content__imgs{
    padding-top: 10px;
    width: 100%;
    height: 100px;
}

.whyChoose-content__imgs img{
    width: 70px;
    height: 70px;
    object-fit: contain;
}

.whyChoose__titleRed{
    color: #ED1C24;
    font-size: 28px;
    font-weight: 600;
}

.whyChoose__title, .interiorSolution__title{
    color: #2A2A2A;
    font-size: 28px;
    font-weight: 600;
}

.BsCalendar2Date{
    font-size: 60px;
}

.whyChoose__contentPara{
    font-size: 12px;
}

.banner__Img{
    width: 100%;
}

.banner__Img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width : 639px) {
    .whyChoose__title, .interiorSolution__title, .whyChoose__titleRed{
        font-size: 26px;
    }
}


@media only screen and (max-width:399px) {
    .banner__Img{
        width: 100%;
        height: 600px;
        background-image: url(../../../../public/images/mobile-banner.jpg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}
@media (min-width:400px) and (max-width: 768px) {
    
    .banner__image{
       width: 100%;
       height: fit-content;
       display: grid;
       place-content: center;
    }

    .banner__Img{
        width: 400px;
        height: 600px;
        background-image: url(../../../../public/images/mobile-banner.jpg);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .banner__Img{
        width: 100%;
        height: 256px;
        background-image: url(../../../../public/images/website-banner.webp);
        background-repeat: no-repeat;
        background-position: center / cover;
        background-size: 100% 100%;
    }
} 

@media (min-width: 1025px) and (max-width: 1280px) {
    .banner__Img{
        width: 100%;
        height: 341px;
        background-image: url(../../../../public/images/website-banner.webp);
        background-repeat: no-repeat;
        background-position: center / cover;
        background-size: 100% 100%;
    }
} 

@media (min-width: 1281px) and (max-width: 1535px) {
    .banner__Img{
        width: 100%;
        height: 426px;
        background-image: url(../../../../public/images/website-banner.webp);
        background-repeat: no-repeat;
        background-position: center / cover;
        background-size: 100% 100%;
    }
} 

@media (min-width: 1536px) and (max-width: 4000px) {
    .banner__Img{
        width: 100%;
        height: 512px;
        background-image: url(../../../../public/images/website-banner.webp);
        background-repeat: no-repeat;
        background-position: center / cover;
        background-size: 100% 100%;
    }
} 



/* Interior Solution */

.interiorSolution__{
    background: rgba(201,201,201,0.1);
    /* opacity: 80%; */
}

.interiorSolution__content{
    cursor: pointer;
}

.interiorSolutionContent__img{
    width: 106px;
    height: 66px;
}

.interiorSolutionContent__img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.interiorSolution__img{
    width: 158px;
    height: 76px;
    border: 1px solid #e7e2e2;
}

.interiorSolution__img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text__color:hover{
    color: #ED1C24;
}

@media only screen and (max-width:379px) {
    .interiorSolutionContent__img{
        width: 126px;
    }
}

@media only screen and ( max-width: 374px) {
    .interiorSolution__container{
        display: grid ;
        grid-template-columns: 165px;
        place-content: center;
    }
}
@media (min-width: 375px) and (max-width:639px) {
    .interiorSolution__container{
        display: grid !important;
        grid-template-columns: 165px 165px !important;
        place-content: center;
        gap: 50px 40px;
    }
    
}

@media only screen and (max-width:375px) {
    .project-container{
        padding: 0px 12px;
    }
}

@media (min-width:376px) and (max-width: 639px){
    .project-container{
        padding: 0px 12px;
    }
}

/* Our Project */

.ourproject__img, .ourproject__img > img{
    border-radius: 8px;
}

.ourproject__img{
    position: relative;
    cursor: pointer;
}

/* .ourproject__img::before{
    content: "Profile";
    width: 100%;
    height: 100%;
    background-color: #2A2A2A;
    position: absolute;
    inset: 0;
    border-radius: 8px;
    color: #fff;
    font-size: 22px;
    display: grid;
    place-content: center;
} */

.ourproject__img:hover::before{
    content: "Profile";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.567);
    position: absolute;
    inset: 0;
    border-radius: 8px;
    color: #fff;
    font-size: 22px;
    display: grid;
    place-content: center;
} 

/* Partners Section */

.partners__slider{
    width: 280px;
    height: 150px;
    background-color: #c9c9c9;
}

.partners{
    -webkit-mask: linear-gradient( 90deg, transparent, white 20%, 180deg white 80% transparent );
    mask: linear-gradient( 90deg, transparent, white 20%, 180deg white 80% transparent );
}

/* Footer */

.footer-about__para{
    color: #7a7a7a;
}


.footer-about__para, .footer-navigator__links{
    font-size: 14px;

}

.about-red{
    color: #ED1C24;
    font-weight: 700;
}

.footer-about__title, .footer-navigator__title{
    font-size: 16px;
    font-weight: 700;
}

/* .footer-about__title{
    margin-top: -30px;
} */
.icons__{
    font-size: 16px;
    font-weight: 700;
}

.copyRights{
    font-size: 12px;
    border-top: 1px solid #d3d8dc;
}

/* Work */

.work-content__img{
    width: 100px;
    height: 100px;
    display: grid;
    place-content: center;
    border-radius: 50%;
    border: 1px solid #c9c9c9;
    z-index: -1;
}

.work-content__img img{
    width: 66px;
    height: 66px;
}

.line__{
    width: 200px;
    border-top: 2px dotted #7a6e77;
}

.work__content{
    font-size: 12px;
}

.footer-navigator__links p{
    transition: all 0.5s ease;
}

.footer-navigator__links p:hover{
    color: #ED1C24;
    transform: translateX(10px);
    transition: all 0.5s ease;
}

.socialIcon-link a:hover{
    color: #ED1C24;
}

.wrk__btn > button{
    width: fit-content;
    height: fit-content;
    padding: 8px 22px;
    background-color: #ED1C24;
    color: #fff;
    border-radius: 8px;
    border: none;
    outline: none;
    font-weight: 500;
}
@media only screen and (max-width:1024px) {
    .work__content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .line__{
        width: 0px;
        height: 100px;
        border-right: 2px dotted #7a6e77;
    
    }
}
@media (min-width:640px) and (max-width:1024px) {
    .work-content__img{
        width: 80px;
        height: 80px;
    }
    .work-content__img img{
        width: 44px;
        height: 44px;
    }
}


.slick-prev, .slick-next{
    display: none !important;
} 



.image__slider{
    width: 300px;
    height: 250px;
}

.image__slider img{
    width: 100%;
    height: 100%;
}   

.slick-slide > div{
    margin: 0px 30px;
}

.arrow {
    background-color: #000;
    position: absolute;
    cursor: pointer;
    z-index: 10;
    width: 26px;
    height: 32px;
    display: grid;
    place-content: center;
    color: #ED1C24;
}
  
.arrow svg {
    transition: color 300ms;
}

.next {
    right: 0%;
    top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
} 

.footer-top__{
    border-top: 1px solid #d3d8dc;
}

.footer__content-name{
    cursor: pointer;
}

.footer__content-name:hover{
    color: #ED1C24;
}

.deleloped{
    color: #ED1C24;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
}

.footer__location{
    background: rgba(201,201,201,0.1);
    border-radius: 8px;
}

.footer__location > span{
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    color: #ED1C24;
    margin: 12px 0px;
}

.footer__locoIcon{
    width: 50px;
    height: 50px;
    border: 2px dashed #d3d8dc;
    border-radius: 50%;
    color: #ED1C24;
    font-size: 22px;
}

.copyRights > span{
   display: block;
}

.footer-form__input > input{
    border: none;
    outline: none;
    border-bottom: 1px solid #ED1C24;
    padding: 0px 4px;
}

.footer__form > button{
    background-color: #ED1C24;
    color: #fff;
    border-radius: 8px;
    border: none;
    outline: none;
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-top: 30px;
}

@media (min-width: 400px) and (max-width: 639px) {
    .footer__form{
        width: 300px;
    }
}