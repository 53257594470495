*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
}



::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.swiper_container {
  height: 38rem;
}

.swiper-slide {
  width: 32rem !important;
  height: 32rem !important;
  position: relative;
}

@media only screen and  (max-width: 768px) {
  .swiper_container {
    height: 28rem;
  }
  .swiper-slide {
    width: 22rem !important;
    height: 22rem !important;
  }
  .swiper-slide img {
    width: 22rem !important;
    height: 22rem !important;
  }
}

@media (min-width: 769px) and  (max-width: 1024px) {
  .swiper_container {
    height: 32rem;
  }
  .swiper-slide {
    width: 26rem !important;
    height: 26rem !important;
  }
  .swiper-slide img {
    width: 26rem !important;
    height: 26rem !important;
  }
}

.swiper-slide img {
  width: 32rem;
  height: 32rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: -10px !important;
  left: auto !important;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #ED1C24;
}