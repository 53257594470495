.popup__container{
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: grid;
  place-content: center;
  z-index: 999;

}

.popup {
    padding: 20px 34px 34px;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0, 0, 0);
    z-index: 9999;
    transition: all 0.5s ease;
    width: 380px;
    height: fit-content;
    position: relative;
    border-radius: 8px;
  }
  
  /* Styles for the close button */
  #closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #ED1C24;
  }

  
  /* Styles for the icon */
  #icon  {
    position: fixed;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #fff;  
    z-index: 9999;
    font-size: 22px; 
  
  }
  
  .chant-icon{
    width: 50px;
    height: 50px;
    background-color: #ED1C24;
    display: grid;
    place-content: center;
    border-radius: 8px;
  }
  
  
  .user-form{
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding-left: 6px;
    margin-top: 32px;
    border-bottom: 1px solid #000;
  }

  .user-form > input{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 6px;
    padding: 0px 12px;
  }


  .popup > form > button{
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: #A70606;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-top: 32px;
  }
  
  .error-msg {
    color: red;
    display: block;
    font-size: 14px;
  }


@media only screen and ( max-width: 380px) {
  .popup{
    width: 100%;
  }
}


